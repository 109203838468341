import clsx from 'clsx';
import React from 'react';

import { Box, useMediaQuery, useTheme } from '@material-ui/core';

interface ContainerProps {
  hideBackground?: boolean;
  minHeight?: number | string;
  minWidth?: number | string;
  margin?: number | string;
  children: React.ReactNode;
}

export function Container(props: ContainerProps) {
  const { children, margin, minHeight, minWidth, hideBackground } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box
      m={margin ?? '0px'}
      minWidth={minWidth}
      minHeight={minHeight ?? isSmallScreen ? 'calc(100vh - 56px)' : 'calc(100vh - 64px)'}
      className={clsx(!hideBackground && 'bg-bgColor')}>
      {children}
    </Box>
  );
}
