import moment from 'moment';

import EditIcon from '@material-ui/icons/BorderColor';
import TrashIcon from '@material-ui/icons/DeleteOutline';

import {
  EntryType,
  MeetingColumnKey,
  type MeetingModel,
  MeetingSortField,
} from 'services/models/meetingTimeEntry.model';
import { SortingDirection } from 'services/models/sorting.model';

import type { ColumnDefinition } from 'components/BanzaiTable';

import { formatTime } from 'utils/helpers/formatTime';

// eslint-disable-next-line consistent-return
function textForEntryType(type: EntryType) {
  // eslint-disable-next-line default-case
  switch (type) {
    case EntryType.ADJUSTMENT:
      return 'Adjustment';
    case EntryType.LATE:
      return 'Late';
    case EntryType.ON_TIME:
      return 'On Time';
  }
}

export const COLUMN_DEFINITIONS: ReadonlyArray<ColumnDefinition<MeetingColumnKey, MeetingSortField, MeetingModel>> = [
  {
    key: MeetingColumnKey.EnteredBy,
    title: 'Entered by',
    width: '18%',
    sortField: MeetingSortField.lastUpdatedBy,
    extractCellContents: meeting => ({
      primary: {
        text: meeting.created.user
          ? `${meeting.created.user.firstName} ${meeting.created.user.lastName}`
          : 'non-user-created',
      },
      subText: { text: meeting.created.user?.email },
    }),
  },
  {
    key: MeetingColumnKey.MonthlyStatement,
    title: 'Monthly Statement',
    width: '15%',
    sortField: MeetingSortField.statementMonth,
    defaultSortDirection: SortingDirection.Desc,
    extractCellContents: meeting => ({
      primary: { text: moment(meeting.statementMonth).format('MMMM YYYY') },
      secondary: { text: textForEntryType(meeting.entryType) },
    }),
  },
  {
    key: MeetingColumnKey.MeetingDate,
    title: 'Meeting Date',
    width: '15%',
    sortField: MeetingSortField.meetingDate,
    defaultSortDirection: SortingDirection.Desc,
    extractCellContents: meeting => ({
      primary: { text: moment(meeting.startedAt).format('L') },
      secondary: { text: `${formatTime(new Date(meeting.startedAt))} - ${formatTime(new Date(meeting.endedAt))}` },
    }),
  },
  {
    key: MeetingColumnKey.MeetingCategory,
    title: 'Meeting Category',
    width: '16%',
    sortField: MeetingSortField.meetingCategory,
    extractCellContents: meeting => ({
      primary: {
        text: meeting.otherCategory
          ? `Other - ${meeting.otherCategory}`
          : meeting.meetingCategory
          ? `${meeting.meetingCategory.name}`
          : 'unknown', // in case there is no active meeting category will use the id ( example: production entires before v2)
      },
      secondary: { text: `${meeting.meetingCategory?.paid ? 'Paid' : 'Unpaid'}` },
    }),
  },
  {
    key: MeetingColumnKey.Practice,
    title: 'Practice',
    width: '12%',
    sortField: MeetingSortField.practice,
    extractCellContents: meeting => ({
      primary: { text: meeting.practice.displayName },
      secondary: { text: meeting.practice.tin },
    }),
  },
  {
    key: MeetingColumnKey.CreationDate,
    title: 'Creation Date',
    width: '12%',
    sortField: MeetingSortField.createdDate,
    defaultSortDirection: SortingDirection.Desc,
    extractCellContents: (meeting: MeetingModel) => {
      const text = moment(meeting.created.timestamp).format('L');
      if (meeting.deleted) {
        return {
          primary: { text },
          secondary: {
            text: '(Deleted)',
            tooltip: {
              text:
                `Deleted by ${meeting.updated.user.firstName} ${meeting.updated.user.lastName}` +
                `, ${moment(meeting.updated.timestamp).format('L')}` +
                ` at ${formatTime(new Date(meeting.updated.timestamp))}`,
              Icon: TrashIcon,
              colorClassName: 'text-error',
            },
          },
        };
      }
      if (meeting.updated.timestamp !== meeting.created.timestamp) {
        return {
          primary: { text },
          secondary: {
            text: '(Edited)',
            tooltip: {
              text:
                `Last Edited by ${meeting.updated.user.firstName} ${meeting.updated.user.lastName}` +
                `, ${moment(meeting.updated.timestamp).format('L')}` +
                ` at ${formatTime(new Date(meeting.updated.timestamp))}`,
              Icon: EditIcon,
            },
          },
        };
      }
      return { primary: { text } };
    },
  },
];
