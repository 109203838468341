import type { IPublicClientApplication } from '@azure/msal-browser';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Client } from '@microsoft/microsoft-graph-client';
import { addBreadcrumb, captureException, captureMessage, configureScope } from '@sentry/react';
import { graphApiRequest, loginRequest, refreshloginRequest } from 'authConfig';
import { usePageVisibility } from 'hooks/usePageVisibility';
import jwtDecode from 'jwt-decode';
import type { JwtPayload } from 'jwt-decode';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { showError } from 'slices/snack';
import { useDispatch } from 'stores';

import { useCurrentUser } from 'services/auth.service';
import type { CurrentUserModel } from 'services/models/auth.model';
import { navigationService } from 'services/navigation/navigation.service';

import { CountDownDialog } from 'components/CountDownDialog';

import { RoutePath } from 'utils/constants/route.constant';
import API from 'utils/helpers/axiosInstance';

interface AuthContextType {
  handleSignOut: () => void;
  handleLogin: () => void;
  inProgress: boolean;
  isAuthenticated: boolean;
  user?: CurrentUserModel | null;
  avatar: string | null;
  isOpenIframe: boolean;
  isOpenCreateDialog: boolean;
  practiceTin: string | null;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

interface DecodedAccessToken extends JwtPayload {
  given_name: string;
  family_name: string;
  unique_name: string;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const isVisible = usePageVisibility();
  const [user, setUser] = useState<CurrentUserModel | null | undefined>();
  const history = useHistory();
  const isOpenIframe = new URLSearchParams(window.location.search).get('iframe');
  if (isOpenIframe && isOpenIframe === 'open') {
    sessionStorage.setItem('isOpenIframe', isOpenIframe);
  }
  const isOpenCreateDialog = new URLSearchParams(window.location.search).get('iframeCreate');
  if (isOpenCreateDialog && isOpenCreateDialog === 'open') {
    sessionStorage.setItem('isOpenCreateDialog', isOpenCreateDialog);
  }
  const practiceTin = new URLSearchParams(window.location.search).get('practiceTin');
  if (practiceTin) {
    sessionStorage.setItem('practiceTin', practiceTin);
  }

  const [avatar, setAvatar] = useState<string | null>(null);
  const [showAlarm, setShowAlarm] = useState<boolean>(false);

  const setSession = (accessToken: string | null): void => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      API.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete API.defaults.headers.common.Authorization;
    }
  };

  const getClient = (accessToken: string) =>
    Client.init({
      authProvider: done => {
        done(null, accessToken);
      },
    });

  const fetchUserAvatar = async () => {
    try {
      const account = instance.getAllAccounts()[0];
      const graphTokenResponse = await instance.acquireTokenSilent({
        ...graphApiRequest,
        account,
      });

      const graphToken = graphTokenResponse.accessToken;
      if (graphToken) {
        const client = getClient(graphToken);
        const response = await client.api('/me/photo/$value').get();
        const avatarUrl = window.URL.createObjectURL(response);
        setAvatar(avatarUrl);
      }
    } catch (error: any) {
      // eslint-disable-next-line
      console.error(error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSignOut = () => {
    // Clear the MSAL cache before logging out
    if (inProgress === InteractionStatus.Logout || inProgress === InteractionStatus.None) {
      try {
        instance.setActiveAccount(null);
        setUser(null);
        instance.logoutRedirect({
          onRedirectNavigate: () => {
            setSession(null);
            localStorage.removeItem('hasLogged');
            sessionStorage.removeItem('isOpenIframe');
            sessionStorage.removeItem('isOpenCreateDialog');
            sessionStorage.removeItem('practiceTin');
            navigationService.toRoute(history, RoutePath.Default);
            return false;
          },
        });
      } catch (error: any) {
        captureException(error);
      }
    }
  };

  const setUserInfo = ({ appRoles, ...user }: CurrentUserModel) => {
    configureScope(scope => {
      scope.setUser(user);
    });
  };

  const getDecodedAccessToken = (token: string): DecodedAccessToken | null => {
    try {
      return jwtDecode<DecodedAccessToken>(token);
    } catch (error) {
      captureMessage('Invalid access token');
      return null;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLogin = async () => {
    localStorage.setItem('commitId', process.env.REACT_APP_COMMIT_ID as string);

    try {
      await instance.loginRedirect(loginRequest);
    } catch (error: any) {
      captureException(error);
    }
  };

  useEffect(() => {
    if (instance) {
      instance
        .handleRedirectPromise()
        .then(res => {
          if (res && res.accessToken) {
            const data = getDecodedAccessToken(res.accessToken);

            if (!data) {
              captureMessage('Invalid access token');
              return;
            }
            setSession(res.accessToken);

            navigationService.toRoute(history, RoutePath.Default);
          }
        })
        .catch(error => {
          captureException(error);
        });
    }
  }, [instance, history]);

  const { currentUser, error: currentUserError } = useCurrentUser();

  const checkTokenExpired = (token: string): boolean => {
    if (!token || token.split('.').length !== 3) {
      return true;
    }

    const decodedPayload = atob(token.split('.')[1]);
    const jsonPayload = JSON.parse(decodedPayload);

    return jsonPayload.exp < Math.floor(Date.now() / 1000);
  };

  const getAccessToken = async (ins: IPublicClientApplication) => {
    if (inProgress !== InteractionStatus.None) {
      return;
    }
    try {
      const account = ins.getAllAccounts()[0];
      if (user) {
        setUserInfo(user);
      }

      if (!account) {
        addBreadcrumb({ type: 'error', message: 'No account found' });
        handleSignOut();
      }

      const storedAccessToken = localStorage.getItem('accessToken');

      if (!storedAccessToken || checkTokenExpired(storedAccessToken)) {
        try {
          const response = await ins.acquireTokenSilent({
            ...refreshloginRequest,
            account,
          });
          setSession(response.accessToken);
        } catch (silentError: any) {
          // If there's an error while refreshing the token, log out the user
          addBreadcrumb({ type: 'error', message: 'update Silent token error' });
          handleSignOut();
        }
      }
    } catch (error: any) {
      addBreadcrumb({ type: 'error', message: 'update Silent token error' });
      handleSignOut();
    }
  };

  const getRefreshAccessToken = async (ins: IPublicClientApplication) => {
    if (inProgress !== InteractionStatus.None) {
      return;
    }
    try {
      const account = ins.getAllAccounts()[0];

      if (!account) {
        addBreadcrumb({ type: 'error', message: 'No account found' });
        handleSignOut();
      }

      try {
        const response = await ins.acquireTokenSilent({
          ...refreshloginRequest,
          account,
        });
        localStorage.setItem('accessToken', response.accessToken);
      } catch (silentError: any) {
        // If there's an error while refreshing the token, log out the user
        addBreadcrumb({ type: 'error', message: 'update Silent token error' });
        handleSignOut();
      }
    } catch (error: any) {
      addBreadcrumb({ type: 'error', message: 'update Silent token error' });
      handleSignOut();
    }
  };

  const handleCloseAlarm = () => {
    setShowAlarm(false);
  };

  const handleExtendSession = () => {
    setShowAlarm(false);
    getRefreshAccessToken(instance);
  };

  useEffect(() => {
    if (isVisible && isAuthenticated) {
      getAccessToken(instance);
    }
    if (!isAuthenticated && !accounts.length && (isOpenIframe || isOpenCreateDialog)) {
      navigationService.toRoute(history, RoutePath.Default);
    }
    if (currentUserError || (!isAuthenticated && !accounts.length)) {
      handleSignOut();
    }
  }, [isVisible, currentUserError, isAuthenticated, accounts]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // To avoid fetch the user on the integration test, we need to make sure that integration test user is not set
    if (isAuthenticated && typeof process.env.REACT_APP_MSAL_INTEGRATION_USER === 'undefined') {
      fetchUserAvatar();
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accounts.length > 0 && inProgress === InteractionStatus.None) {
      setUser(currentUser);
    } else if (inProgress === InteractionStatus.None) {
      setUser(null);
    }
  }, [currentUser, inProgress]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!user || showAlarm || isOpenIframe || isOpenCreateDialog) return undefined;
    let activeUser = setTimeout(() => setShowAlarm(true), 14 * 60 * 1000);

    function handleEvent() {
      if (user) {
        if (Object.keys(user.appRoles).length > 0) {
          clearTimeout(activeUser);
          activeUser = setTimeout(() => setShowAlarm(true), 14 * 60 * 1000);
        } else {
          clearTimeout(activeUser);
          dispatch(showError({ message: 'You have no permission. Please contact the support team.' }));
          handleSignOut();
        }
      } else {
        handleSignOut();
      }
    }

    document.addEventListener('mousemove', handleEvent);
    document.addEventListener('mousedown', handleEvent);
    document.addEventListener('keypress', handleEvent);

    return () => {
      clearTimeout(activeUser);
      document.removeEventListener('mousemove', handleEvent);
      document.removeEventListener('mousedown', handleEvent);
      document.removeEventListener('keypress', handleEvent);
    };
  }, [user, showAlarm]); // eslint-disable-line react-hooks/exhaustive-deps

  const initValues = useMemo(
    () => ({
      handleSignOut,
      handleLogin,
      inProgress: inProgress !== InteractionStatus.None || (isAuthenticated && !user),
      isAuthenticated,
      user,
      avatar,
      isOpenIframe: !!isOpenIframe,
      isOpenCreateDialog: !!isOpenCreateDialog,
      practiceTin,
    }),
    [
      handleSignOut,
      handleLogin,
      inProgress,
      isAuthenticated,
      user,
      avatar,
      isOpenIframe,
      isOpenCreateDialog,
      practiceTin,
    ],
  );

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AuthContext.Provider value={initValues}>
      {children}
      <CountDownDialog
        isOpen={showAlarm}
        message='Login Session Expiring'
        handleCancel={handleCloseAlarm}
        handleExtendSession={handleExtendSession}
        handleSignOut={handleSignOut}
      />
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
